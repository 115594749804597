import { Box, Tab, Tabs, Typography, useMediaQuery } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import React, { useEffect, useRef, useState } from "react";
import FilterOptionsSidebar from "./FilterOptionsSidebar";
import ComponentCard from "../home/components/ComponentCard";
import { ButtonPrimary } from "../../shared/components/button/Buttons";
import { ImageContainer } from "../../shared/components/ImageContainer";
import { EmptySearchIcon, WikiLogo } from "../../assets/icons/icons";
import { useGlobalContext } from "../../App";
import { getAllComponents } from "../../services/component";
import DefaultLoading from "../../shared/Loading/DefaultLoading";
import { useLocation, useNavigate, useNavigationType } from "react-router-dom";
import Footer from "../../main/Footer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { CrossIconSvg } from "../../assets/icons/CrossIconSvg";
import Search from "../../shared/components/Search";
import { getUserById } from "../../services/user";
import ProjectComponentDetailScreenPopUp from "../projectComponent/ProjectComponentDetailScreenPopUp";
import LoginDialog from "../../login/LoginDialog";
import SignupDialog from "../../signup/SignupDialog";
import ResetPasswordDialog from "../../resetPassword/ResetPasswordDialog";
import Seperator2 from "../../shared/components/Seperator2";

type Props = {};

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    maxWidth: `100%`,
    opacity: 1,
    background: "transparent",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  background: "transparent",
  boxShadow: "none !important",
  height: "120px",
  "@media (max-width: 600px)": {
    height: "96px",
  },
  opacity: 1,
  marginRight: "0px",
}));

const SearchScreen = (props: Props) => {
  const {
    searchProductBy,
    setActiveNavigation,
    setSearchProductBy,
    userDetails,
    activeLoginDialog,
    setActiveLoginDialog,
    setActiveSignupDialog,
    activeSignupDialog,
    activeResetDialog,
    setActiveResetDialog,
  } = useGlobalContext();
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();

  const [componentDetailsPopup, setComponentDetailsPopup] =
    useState<boolean>(false);
  const [selectedComponent, setSelectedComponent] = useState(null);
  const handleOpenPopup = (component: any) => {
    setSelectedComponent(component);
    setComponentDetailsPopup(true);

    // Update the URL without navigating
    const newUrl = `component/${component.blurbUrl}`;
    window.history.replaceState(null, "", newUrl);
  };

  const breakpoint_down_769 = useMediaQuery(theme.breakpoints.down(700));
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));

  //This is debouncing code for search
  const DEBOUNCE_DELAY = 700;

  // TabPanel handling states and functions
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  // Refrence for search input
  const searchInputRef = useRef<HTMLInputElement | null>(null);
  const loaderRef = useRef<HTMLDivElement>(null);

  const [loadMoreLoading, setLoadMoreLoading] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  // const [scrollPosition, setScrollPosition] = useState<any>(0);
  // const [scrollPosition, setScrollPosition] = useState<any>(
  //   Number(sessionStorage.getItem("scrollPosition") || 0)
  // );

  // ------Sidebar handling states and functions------
  const [open, setOpen] = React.useState(true);

  const [activeFilters, setActiveFilters] = useState<any>({
    categories: [],
    tags: [],
    licenses: [],
    collections: [],
  });
  const [filteredList, setFilteredList] = useState<any>([]);
  const [userSubscriptions, setUserSubscriptions] = useState([]);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [components, setComponents] = useState<any>();

  // current page and rows per page handling states and functions
  // const [page, setPage] = useState(1);
  const [page, setPage] = useState(
    Number(sessionStorage.getItem("currentPage")) || 1
  );

  // const rowsPerPage = 20 * page;  Note: this is the logic for scroll retain position
  const rowsPerPage = 20;
  const [hasMore, setHasMore] = useState(true);

  const fetchAllComponents = async () => {
    const list: any = localStorage.getItem("componentsList");
    if (list) {
      const sortedList = JSON.parse(list)?.paginatedComponents.sort(
        (a: any, b: any) => {
          return (
            new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
          );
        }
      );
      setComponents(sortedList);
    } else {
      await getAllComponents({ page: 1, pageSize: 18 })
        .then((res: any) => {
          setComponents(res?.paginatedComponents);
        })
        .catch((err: any) => {
          console.log("err", err);
        });
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchAllComponents();
  }, []);
  const getComponents = async (filters?: any, resetPage = false) => {
    try {
      await getAllComponents(filters)
        .then((res: any) => {
          if (resetPage) {
            setFilteredList(res);
          } else {
            setFilteredList((prevList: any) => ({
              paginatedComponents: [
                ...(prevList?.paginatedComponents || []),
                ...(res?.paginatedComponents || []),
              ],
            }));
          }
          setHasMore(res.totalComponents > rowsPerPage * page);
          setLoadMoreLoading(false);
          setLoading(false);
        })
        .catch((err: any) => {
          console.log("err", err);
          setLoadMoreLoading(false);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoadMoreLoading(false);
      setLoading(false);
    }
  };

  const handleLoadMore = () => {
    setLoadMoreLoading(true);
    // setPage((prevPage) => prevPage + 1);
    setPage((prevPage) => {
      const newPage = prevPage + 1;
      return newPage;
    });
  };

  const handleChangeActiveFilters = (type: string, value: string) => {
    setActiveFilters((prev: any) => {
      switch (type) {
        case "categories": {
          const isCategorySelected = prev?.categories?.includes(value);
          return {
            ...prev,
            categories: isCategorySelected
              ? prev?.categories?.filter(
                  (category: string) => category !== value
                )
              : [...prev?.categories, value],
          };
        }
        case "tags": {
          const isTagSelected = prev?.tags?.includes(value);
          return {
            ...prev,
            tags: isTagSelected
              ? prev?.tags?.filter((t: string) => t !== value)
              : [...prev?.tags, value],
          };
        }
        case "collections": {
          const isCollectionSelected = prev?.collections?.includes(value);
          return {
            ...prev,
            collections: isCollectionSelected
              ? prev?.collections?.filter((t: string) => t !== value)
              : [...prev?.collections, value],
          };
        }
        case "licenses": {
          const isLicenseSelected = prev?.licenses?.includes(value);
          return {
            ...prev,
            licenses: isLicenseSelected
              ? prev?.licenses?.filter((t: string) => t !== value)
              : [...prev?.licenses, value],
          };
        }
        default:
          return prev;
      }
    });
  };

  const handleClearAllFilters = () => {
    setActiveFilters(() => {
      return {
        categories: [],
        licenses: [],
        collections: [],
      };
    });
  };

  const getUserSubscriptionsDetails = async (id: string) => {
    const response = await getUserById(id);
    const subscriptions = response?.data?.subscriptions;

    if (subscriptions?.length > 0) {
      setUserSubscriptions(subscriptions);
      for (let subscription of subscriptions) {
        if (subscription.status === "ACTIVE") {
          setIsSubscribed(true);
        }
      }
    }
  };

  useEffect(() => {
    setPage(page);
    setLoading(true);
    if (hasMore && !loading) {
      getComponents({
        searchBy: searchProductBy,
        // page: savedPage,
        page: page,
        pageSize: rowsPerPage,
        categories: activeFilters?.categories || [],
        collections: activeFilters?.collections || [],
        licenses: activeFilters?.licenses || [],
      });

      setLoading(false);
    }
  }, [page]);

  // Infinite scroll handler using IntersectionObserver
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting && hasMore && !loading) {
          handleLoadMore();
        }
      },
      { threshold: 1.0 }
    );

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current);
      }
    };
  }, [loading, hasMore]);

  useEffect(() => {
    const handleSearch = () => {
      setLoading(true);
      setPage(1);
      const incomingTag = location?.state?.tag || "";

      //Fix this later
      const activeTags = [
        ...(activeFilters?.categories || []),
        ...(activeFilters?.collections || []),
        ...(activeFilters?.licenses || []),
      ];
      getComponents(
        {
          searchBy: searchProductBy,
          page: 1,
          pageSize: rowsPerPage,
          categories: activeFilters?.categories || [],
          collections: activeFilters?.collections || [],
          licenses: activeFilters?.licenses || [],
        },
        true
      );
    };

    const handler = setTimeout(() => {
      handleSearch();
    }, DEBOUNCE_DELAY);

    const handleKeyDown = (event: any) => {
      if (event.code === "Space") {
        clearTimeout(handler);
        handleSearch();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      clearTimeout(handler);
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [searchProductBy, activeFilters]);

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (userDetails) {
      getUserSubscriptionsDetails(userDetails._id);
    }
  }, [userDetails]);

  //Stop scrolling when popup is open
  useEffect(() => {
    if (componentDetailsPopup) {
      // Disable scrolling
      document.body.style.overflow = "hidden";
    } else {
      // Enable scrolling
      document.body.style.overflow = "auto";
    }

    // Cleanup on component unmount
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [componentDetailsPopup]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        flex: 1,
        minHeight: "100vh",
      }}
    >
      {/* -----Appbar section----- */}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop:{xs:"20px",sm:"0px",lg:"0px"},
        }}
      >
        <AppBar
          position="relative"
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            backgroundColor: "#0C0C0C !important",
            marginRight: !open ? "0px" : "0px",
            borderBottom:
              window.location.pathname !== "/"
                ? "1px solid rgba(255, 255, 255, 0.12)"
                : "none",
            flex: "1",
            opacity: 1,
            width: {
              xs: "100%",
            },
            maxWidth: {
              xs: "100%",
            },
            transition: theme.transitions.create(["width"], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
            [theme.breakpoints.down("lg")]: {
              marginLeft: 0,
              ...(open && {
                transition: theme.transitions.create(["margin"], {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.enteringScreen,
                }),
              }),
            },
          }}
          open={open}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              height: "100%",
              width: "100%",
              gap: { xs: "12px", sm: "35px" },
              position: "relative",
              paddingLeft: "24px",
            }}
          >
            <Box
              onClick={() => {
                setActiveNavigation((prevNav: any) => {
                  window.localStorage.setItem("ACTIVE_NAVIGATION", "/");
                  return "/";
                });
                navigate("/");
              }}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                cursor: "pointer",
                width: {xs:"46px",sm:"54px",lg:"54px"},
              }}
            >
              <ImageContainer
                style={{ width: "100%", height: "100%" }}
                height="54px"
                title="Main Logo"
              >
                {WikiLogo}
              </ImageContainer>
            </Box>

            <Search
              id="appbarSearch"
              searchFormStyles={{
                boxShadow: "none !important",
                maxWidth: "90%",
                color: "white",
                // fontSize: "24px",
                // fontSize: {xs:"14px",sm:"24px",lg:"24px"},
              }}
              style={{
                boxShadow: "none !important",
                // fontSize: "24px",
                // fontSize: {xs:"14px", sm:"24px",lg:"24px"},
                // "@media (max-width: 600px)": {
                //   fontSize: "14px",
                // },
                maxHeight: "44px",
                color: "white",
                fontWeight: "400",
                paddingLeft: "0px",
                width: "100%",
                outline: "none",
                border: "none",
                borderRadius: "0px",
                backgroundColor: "#0C0C0C",
                lineHeight: { xs: "16px", lg: "22px" },
                letterSpacing: { xs: "8%" },
              }}
              // placeholderStyle={{
              //   fontSize: "24px",
              //   // fontSize: {xs:"14px",sm:"24px",lg:"24px"},
              //   // paddingLeft: "10px",
              //   "@media (max-width: 600px)": {
              //     fontSize: "14px !important",
              //   },
              // }}
              hideIcon={true}
              value={searchProductBy}
              onChange={(e) => {
                setSearchProductBy(e.target.value);
              }}
              placeholder="Search components, elements..."
              inputRef={searchInputRef}
            />

            <Box
              onClick={() => {
                setSearchProductBy("");
                navigate(-1);
              }}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: { xs: "24px", sm: "44px" },
                height: { xs: "24px", sm: "44px" },
                borderRadius: "50%",
                boxShadow: "inset 0px 0px 0px 2px #3D3D3D",
                position: "absolute",
                right: 24,
                cursor: "pointer",
              }}
            >
              {isXs ? (
                <CrossIconSvg width="13px" height="13px" color="white" />
              ) : (
                <CrossIconSvg width="24px" height="24px" color="white" />
              )}
            </Box>
          </Box>
        </AppBar>
      </Box>
      {/* -----End Appbar section----- */}

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          height: "100%",
          flex: 1,
          minHeight: `calc(100vh - 120px)`,
        }}
      >
        {!breakpoint_down_769 && (
          <FilterOptionsSidebar
            activeFilters={activeFilters}
            handleChangeActiveFilters={handleChangeActiveFilters}
            handleClearAllFilters={handleClearAllFilters}
          />
        )}

        {/* Filtered content Section */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            // padding: "24px",
            padding: "18px 15px 18px 15px",
            pt:{xs:"40px"},
            width: "100%",
            height: "100%",
            gap: "24px",
            // gap: "20px",
          }}
        >
          <TabPanel value={value} index={0}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                height: "100%",
              }}
            >
              {/* ------Filtered Cards section------ */}
              {loading ? (
                <Box
                  sx={{
                    width: "100%",
                    height: "80vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: "42px",
                      height: "42px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <DefaultLoading />
                  </Box>
                </Box>
              ) : filteredList?.paginatedComponents?.length > 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    flex: 1,
                    width: "100%",
                    gap: "16px",
                  }}
                >
                  <Box
                    sx={{
                      display: "grid",
                      width: "100%",
                      boxSizing: "border-box",
                      gridGap: "16px",
                      alignItems: "center",
                      justifyContent: "start",
                      gridTemplateColumns:
                        filteredList?.paginatedComponents?.length <= 5
                          ? "repeat(auto-fit, minmax(0, 360px))"
                          : {
                              xs: "repeat(auto-fit, minmax(100%, 1fr))",
                              sm: "repeat(auto-fit, minmax(100%, 1fr))",
                              md: "repeat(auto-fit, minmax(360px, 1fr))",
                              lg: "repeat(auto-fit, minmax(360px, 1fr))",
                            }, // Adjust card size and fit into available space
                      "@media screen and (max-width: 979px)": {
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        flexWrap: "wrap",
                      },
                    }}
                  >
                    {filteredList?.paginatedComponents?.map(
                      (item: any, index: number) => {
                        return (
                          <Box
                            key={index}
                            sx={{
                              maxWidth: {
                                xs: "100%",
                                sm: "100%",
                                lg: "100%",
                              },
                              minWidth: {
                                xs: "100%",
                                sm: "100%",
                                lg: "100%",
                              },
                              width: "100%",
                              // height: "327px",
                              justifySelf: "center",
                            }}
                          >
                            <ComponentCard
                              data={item}
                              isSubscribed={isSubscribed}
                              // scrollPosition={scrollPosition}
                              filteredList={filteredList}
                              onEyeIconClick={() => handleOpenPopup(item)}
                              length={filteredList?.paginatedComponents?.length}
                            />
                          </Box>
                        );
                      }
                    )}
                  </Box>

                  {/* Loader reference for infinite scroll */}
                  <Box
                    sx={{
                      height: "24px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    ref={loaderRef}
                  >
                    {" "}
                    {loadMoreLoading && (
                      <Box
                        sx={{
                          width: "24px",
                          height: "24px",
                        }}
                      >
                        {/* <DefaultLoading width="24px" height="24px" /> */}
                      </Box>
                    )}
                  </Box>
                </Box>
              ) : (
                // ------------No results found UI------------
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    // height: "70vh",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "16px",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                      width: "fit-content",
                      mt: "48px",
                      mb: "70px",
                    }}
                  >
                    <ImageContainer
                      imageContainerStyles={{
                        width: { xs: "100px", sm: "166px" },
                        height: { xs: "100px", sm: "166px" },
                      }}
                      title="find folder"
                    >
                      {EmptySearchIcon}
                    </ImageContainer>
                    <Typography
                      variant="h4"
                      sx={{
                        color: "white",
                        fontWeight: "600",
                        fontSize: "24px",
                        lineHeight: "28px",
                      }}
                    >
                      Sorry! No results found
                    </Typography>
                    <Typography
                      sx={{
                        color: "rgba(255, 255, 255, 0.5)",
                        fontWeight: "600",
                        fontSize: "16",
                        width: "70%",
                        lineHeight: "22px",
                      }}
                      variant="body1"
                    >
                      Please tweak your flters, or hit the button below to reset
                      all.
                    </Typography>
                    {/* <ButtonPrimary
                      onClick={() => {
                        setActiveFilters({
                          categories: [],
                          tags: [],
                        });
                      }}
                      sx={{
                        height: "48px",
                        width: "141px",
                        borderRadius: "10px",
                      }}
                      LabelStyle={{ fontSize: "18px", fontWeight: "500" }}
                      label="Reset Filters"
                    /> */}
                  </Box>

                  {/* Latest components  */}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      flex: 1,
                      width: "100%",
                      gap: "32px",
                    }}
                  >
                    <Box sx={{ width: "100%" }}>
                      <Seperator2 />
                    </Box>
                    <Typography variant="h3" color={"white"}>
                      Latest Components
                    </Typography>

                    <Box
                      sx={{
                        display: "grid",
                        width: "100%",
                        boxSizing: "border-box",
                        gridGap: "16px",
                        alignItems: "center",
                        justifyContent: "start",
                        gridTemplateColumns: {
                          xs: "repeat(auto-fit, minmax(100%, 1fr))",
                          sm: "repeat(auto-fit, minmax(100%, 1fr))",
                          md: "repeat(auto-fit, minmax(360px, 1fr))",
                          lg: "repeat(auto-fit, minmax(360px, 1fr))",
                        },
                        "@media screen and (max-width: 979px)": {
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          flexWrap: "wrap",
                        },
                      }}
                    >
                      {components?.map((data: any, index: number) => {
                        return (
                          <Box
                            key={index}
                            sx={{
                              maxWidth: "100%",
                              minWidth: "100%",
                            }}
                          >
                            <ComponentCard
                              data={data}
                              isSubscribed={isSubscribed}
                              onEyeIconClick={() => handleOpenPopup(data)}
                            />
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </TabPanel>
        </Box>
      </Box>

      {/* componentDetailsPopup when user click any perticular component  */}
      {componentDetailsPopup && (
        <ProjectComponentDetailScreenPopUp
          component={selectedComponent}
          onClose={() => {
            window.history.replaceState(null, "", "/search");
            setComponentDetailsPopup(false);
          }}
        />
      )}
      {/* Login Dialog  */}
      {activeLoginDialog && (
        <LoginDialog
          activeDialog={activeLoginDialog}
          handleDialog={setActiveLoginDialog}
        />
      )}
      {/* Sign up Dialog  */}
      {activeSignupDialog && (
        <SignupDialog
          activeDialog={activeSignupDialog}
          handleDialog={setActiveSignupDialog}
        />
      )}
      {/* Active reset Dialog  */}
      {activeResetDialog && (
        <ResetPasswordDialog
          activeDialog={activeResetDialog}
          handleDialog={() => {
            setActiveResetDialog(false);
            setActiveLoginDialog(true);
          }}
        />
      )}
      {/* --------Footer Section-------- */}
      <Footer />
    </Box>
  );
};

export default SearchScreen;

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      sx={{ width: "100%" }}
      className="HideScrollbar"
      // sx={{ maxHeight: SECTION_MIN_HEIGHT }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ flex: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}
