import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { CloseSharp } from "@mui/icons-material";
import { ButtonPrimary } from "../../../../../shared/components/button/Buttons";
import { colors } from "../../../../../utils/colors";
import CrossIcon from "../../../../../assets/icons/Cross Icon/CrossIcon";
import SwitchToYearlyPlanIcon from "../../../../../assets/icons/switchToyearlyPlanIcon/SwitchToYearlyPlanIcon";

type Props = {
  activeDialog: any;
  setActiveSwitchPlan: any;
  handleConfirmUpdatePlan?: any;
  data?: any;
};

const ConfirmSwitchPlanDialog: React.FC<Props> = (props) => {
  const { data } = props;

  return (
    props?.activeDialog && (
      <>
        <Box
          sx={{
            position: "fixed", // Ensure the backdrop covers the entire screen
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            bgcolor: "rgba(0, 0, 0, 0.60)",
            zIndex: 5000,
          }}
        >
          {/* Apply blur effect to only this background */}
          <Box
            sx={{
              width: "100vw",
              height: "100vh",
              border: "1px solid red",
              bgcolor: "rgba(0, 0, 0, 0.60)",
              filter: "blur(62px)",
            }}
          />
        </Box>

        <Box
          sx={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 6000,
          }}
        >
          {/* ------Dialog card------ */}
          <Box
            sx={{
              width: { xs: "90vw", sm: "762px", lg: "762px" },
              maxWidth: { xs: "90vw", sm: "762px", lg: "762px" },
              padding: { xs: "24px", sm: "40px" },
              height: "auto",
              bgcolor: "#1B1B1B",
              borderRadius: "24px",
              border: "1px solid #3D3D3D",
              cursor: "default",
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap:"16px"
            }}
          >
            {/* Cancel Icon  */}
            <Box
              onClick={(e) => {
                e.stopPropagation();
                props?.setActiveSwitchPlan(false);
              }}
              sx={{
                position: "absolute",
                right:"24px",
                top: "24px",
                cursor: "pointer",
                color: "white",
              }}
            >
              <CrossIcon />
            </Box>

            {/* Icon  */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <SwitchToYearlyPlanIcon />
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                gap: "32px",
                maxWidth: "546px",
                width: "100%",
                textAlign: "center",
              }}
            >
              <Box>
                <Typography
                  sx={{ fontSize: "32px", fontWeight: "600", color: "#FFFFFF" }}
                >
                  Upgrade membership
                </Typography>
                <Typography
                  sx={{
                    mt: "18px",
                    fontSize: "18px",
                    fontWeight: "400",
                    color: "#8D8D8D",
                  }}
                >
                  You will be charged ${(data?.update_summary?.result?.amount / 100).toFixed(2)}
                </Typography>
              </Box>

              <Box
                sx={{
                  width: "100%",
                  bgcolor: "#292929",
                  border: "1px solid #3D3D3D",
                  borderRadius: "14px",
                  py: "15px",
                }}
              >
                <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: "16px",
                    fontWeight: "400",
                    color: "#8D8D8D",
                    lineHeight: "24px",
                  }}
                >
                  You will be billed for the prorated amount.
                </Typography>
              </Box>

              {/* ------Action buttons container------ */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row-reverse" },
                  alignItems: "center",
                  gap: "10px",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <ButtonPrimary
                  onClick={() => {
                    props?.handleConfirmUpdatePlan();
                  }}
                  sx={{
                    width: "100%",
                  }}
                  label="Confirm Update Subscription"
                />

                <ButtonPrimary
                  onClick={() => {
                    //redirect on new page on new tab
                    window.open(
                      data?.management_urls?.update_payment_method,
                      "_blank"
                    );
                  }}
                  sx={{
                    background: "transparent",
                    border: "1px solid #3D3D3D",
                    width: "100%",
                    ":hover": {
                      bgcolor: "#1B1B1B",
                      boxShadow:"none"
                    },
                  }}
                  LabelStyle={{
                    color: "white",
                  }}
                  label="Update Payment Method"
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    )
  );
};

export default ConfirmSwitchPlanDialog;
