import { Box, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import Chip from "./../shared/components/Chip";
import NewsletterSection from "./../main/NewsletterSection";
// import { capturePayment, createPayment } from "../services/authentication";
import MembershipPlans from "./setting/tabs/membership/membershipDialog/MembershipPlans";
import { getUserIdFromCookies } from "../utils/extensions";
import { getUserActiveSubscription } from "../services/component";
import Seperator2 from "../shared/components/Seperator2";
import { useTheme } from "@mui/material/styles";

type Props = {};

const Pricing = (props: Props) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const [activePlan, setActivePlan] = useState<any>({});
  const [activeSubscriptions, setActiveSubscriptions] = useState<any>({});

  const userId = getUserIdFromCookies();
  const getSubscriptions = async () => {
    await getUserActiveSubscription(userId)
      .then((res: any) => {
        setActivePlan(res?.activeSubscriptionName);
        setActiveSubscriptions(res?.userSubscriptions);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getSubscriptions();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap:  { xs: "40px", sm: "50px", md: "50px" },
        width: "100%",
        paddingY: { xs: "24px", sm: "40px", md: "80px" },
        paddingBottom: { xs: "40px" },
      }}
    >
      {/* ------Header Section------ */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: { xs: "16px", sm: "24px", md: "24px" },
          textAlign: "center",
          alignItems: "center",
          paddingX: { xs: "24px", sm: "40px", md: "80px" },
        }}
      >
        <Chip
          sx={{
            height: isXs?"32px":"48px",
            borderRadius: "79px",
            width: isXs?"155px":"182px",
            padding:"3px 29px"
          }}
          title="Our Pricing"
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            gap: "16px",
          }}
        >
          <Typography
            sx={{
              color: "white",
              fontWeight: "600",
              fontSize: {xs:"32px",sm:"58px",lg:"58px"},
              width: { xs: "100%", sm: "100%", md: "100%", lg: "100%" },
              lineHeight: "110%",
              background:
                "linear-gradient(81deg, rgba(255,255,255,1) 0%, rgba(48,48,48,1) 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
            // variant="h1"
          >
            UI Wiki Premium
          </Typography>
          <Typography
            variant="h4"
            sx={{
              color: "rgba(255, 255, 255, 0.5)",
              fontWeight: "400",
              width: { xs: "100%", sm: "80%", md: "60%", lg: "60%" },
              fontSize: "18px !important",
            }}
          >
            Instant access to over 2,500 UI components, with fresh design
            inspiration added daily.
          </Typography>
        </Box>
      </Box>

      {/* ------Pricing Section------ */}
      <MembershipPlans
        activePlan={activePlan}
        activeSubscriptions={activeSubscriptions}
      />

      {/* --------Seperator -------- */}
      {isXs && (
        <Box sx={{ width: "100%", paddingX: { xs: "24px" } }}>
          <Seperator2 />
        </Box>
      )}

      {/* -----Newsletter Section----- */}
      <Box
        sx={{ width: "100%", paddingX: { xs: "24px", sm: "40px", md: "80px" } }}
      >
        <NewsletterSection />
      </Box>
    </Box>
  );
};

export default Pricing;

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      sx={{ width: "100%" }}
      className="HideScrollbar"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ flex: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}
