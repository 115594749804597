import React, { useEffect, useRef, useState } from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { CloseSharp } from "@mui/icons-material";
import {
  ButtonPrimary,
  ButtonSecondary,
} from "../../../../shared/components/button/Buttons";
import { CameraIconSvg } from "../../../../assets/icons/CameraIconSvg";
import { PlaceholderAvatar } from "../../../../assets/images/image";
import Dropzone, { DropzoneRef } from "react-dropzone";
import AvatarDialog from "./AvatarDialog";
import { uploadDocument } from "../../../../services/component";
import { toastError, toastSuccess } from "../../../../utils/ToastMessage";
import { useGlobalContext } from "../../../../App";
import { updateUserDetails } from "../../../../services/user";
import CrossIcon from "../../../../assets/icons/Cross Icon/CrossIcon";
import { useTheme } from "@mui/material/styles";

type Props = {
  activeDialog: any;
  handleDialog?: any;
};

const ProfileDialog: React.FC<Props> = (props) => {
  const { userDetails, setUserDetails } = useGlobalContext();
  const dropZoneRef = useRef<DropzoneRef | null>(null);
  useEffect(() => {}, [props?.activeDialog]);
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  
  const [selectedProfile, setSelectedProfile] = useState<any>("");
  const [selectedAvatar, setSelectedAvatar] = useState<any>("");
  const [activeAvatarDialog, setActiveAvatarDialog] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);

  const onFileDrop = async (file: any) => {
    setSelectedProfile(file[0]);
  };

  useEffect(() => {
    if (selectedProfile) {
      handleSaveAvatar();
    }
  }, [selectedProfile]);

  const handleChangeAvatar = (data?: any) => {
    if (data) {
      setSelectedAvatar(data);
    }
    setActiveAvatarDialog(false);
  };

  const handleSaveAvatar = async () => {
    setLoading(true);
    let uploadedProfile: any = "";
    if (selectedProfile) {
      await uploadDocument(selectedProfile)
        .then((res: any) => {
          uploadedProfile = res?.data[0];
        })
        .catch(() => {
          toastError(
            "Ops! Something went wrong.",
            "Failed uploading the user profile."
          );
          setLoading(false);
          return;
        });
    }

    await updateUserDetails({
      userId: userDetails?.id,
      avatar: uploadedProfile?.url || userDetails?.avatar,
      secondaryAvatar: selectedAvatar?.value || userDetails?.secondaryAvatar,
    })
      .then((res: any) => {
        toastSuccess(
          "Successfully updated the user details.",
          "User Pofile is successfully updated."
        );
        // Update the context with new user details
        setUserDetails((prevDetails: any) => ({
          ...prevDetails,
          avatar: uploadedProfile?.url || userDetails?.avatar,
          secondaryAvatar:
            selectedAvatar?.value || userDetails?.secondaryAvatar,
        }));
        props?.handleDialog({
          profile: uploadedProfile?.previewURL,
          avatar: selectedAvatar?.value,
        });
      })
      .catch((err: any) => {
        toastError(
          "Ops! Something went wrong.",
          "Failed uploading the user details."
        );
      });
    setLoading(false);
  };
  return (
    props?.activeDialog && (
      <>
        <Box
          sx={{
            position: "fixed", // Ensure the backdrop covers the entire screen
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            bgcolor: "rgba(0, 0, 0, 0.60)",
            zIndex: 5000, // Set zIndex below the dialog
          }}
        >
          {/* Apply blur effect to only this background */}
          <Box
            sx={{
              width: "100vw",
              height: "100vh",
              border: "1px solid red",
              bgcolor: "rgba(0, 0, 0, 0.60)",
              filter: "blur(62px)",
            }}
          />
        </Box>

        <Box
          sx={{
            position: "fixed", // Position the dialog above the backdrop
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)", // Center the dialog
            zIndex: 6000, // Higher zIndex than the backdrop
            width: { xs: "90%", sm: "fit-content", lg: "fit-content" },
          }}
        >
          {activeAvatarDialog ? (
            <AvatarDialog
              handleDialog={handleChangeAvatar}
              activeDialog={activeAvatarDialog}
              activeId={selectedAvatar?.key}
            />
          ) : (
            <Box
              sx={{
                width: { xs: "100%", sm: "460px" },
                maxWidth: "460px",
                padding: { xs: "24px", sm: "24px" },
                height: "auto",
                bgcolor: "#1B1B1B",
                borderRadius: "24px",
                border: "1px solid #3D3D3D",
                cursor: "default",
                position: "relative",
                margin: { xs: "auto", sm: "0" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: { xs: "24px", sm: "42px", lg: "42px" },
                }}
              >
                <Box
                  onClick={(e) => {
                    e.stopPropagation();
                    props?.handleDialog();
                  }}
                  sx={{
                    position: "absolute",
                    right: "24px",
                    top: "24px",
                    cursor: "pointer",
                    color: "white",
                  }}
                >
                  <CrossIcon width={"24px"} height={"24px"}/>
                </Box>
                
                <Box
                  sx={{
                    paddingX: { xs: "24px", sm: "60px" , lg: "60px" },
                    width: "100%", 
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: { xs: "30px", sm: "23px"},
                    mt: {xs:"0px",md:"38px" },
                  }}
                >
                  {/* -----Upload Profile image----- */}
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "16px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "50%",
                        boxShadow: "inset 0px 0px 0px 2px #3D3D3D",
                        bgcolor: "background.default",
                        position: "relative",
                        // width: "144px",
                        // height: "144px",
                        width: { xs: "80px", sm: "144px" },
                        height: { xs: "80px", sm: "144px" },
                      }}
                    >
                      <Dropzone
                        accept={{
                          "image/png": [".png"],
                          "image/jpeg": [".jpeg", ".jpg"],
                        }}
                        ref={dropZoneRef}
                        disabled={false}
                        multiple={false}
                        onDrop={(file: any) => {
                          onFileDrop(file);
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div
                            style={{
                              width: "100%",
                              height: "100%",
                              display: "flex",
                              flex: 1,
                              cursor: "pointer",
                            }}
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            {selectedProfile || userDetails?.avatar ? (
                              <Box
                                sx={{
                                  width: "100%",
                                  height: "100%",

                                  backgroundImage:
                                    selectedProfile instanceof File
                                      ? `url(${URL.createObjectURL(
                                          selectedProfile
                                        )})`
                                      : userDetails?.avatar &&
                                        `url(${userDetails?.avatar})`,
                                  flex: 1,
                                  backgroundSize: "cover",
                                  backgroundPosition: "center",
                                  backgroundRepeat: "no-repeat",
                                  borderRadius: "50%",
                                  position: "relative",
                                  "&:hover .overlay": {
                                    opacity: 1, // Make the overlay visible on hover
                                  },
                                }}
                              />
                            ) : (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: "100%",
                                  height: "100%",
                                }}
                              >
                               {!isXs ? <CameraIconSvg width="64px" height="64px" /> : <CameraIconSvg width="30px" height="27px" />}
                              </Box>
                            )}
                          </div>
                        )}
                      </Dropzone>
                    </Box>

                    <Typography
                      sx={{ 
                        color: "rgba(255, 255, 255, 0.5)",
                        fontSize:"16px",
                        lineHeight:"24px",
                        letterSpacing:"-2%",
                        fontWeight:"500",
                        width:{ xs: "96px", sm: "auto", lg: "auto" },
                      }}
                      // variant="body1"
                    >
                      Upload Photo
                    </Typography>
                  </Box>

                  {/* -----Choose Avatar----- */}
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "16px",
                    }}
                  >
                    <Box
                      onClick={() => {
                        setActiveAvatarDialog(true);
                      }}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "50%",
                        boxShadow: "inset 0px 0px 0px 1px #3D3D3D",
                        bgcolor: "background.default",
                        position: "relative",
                        // width: "144px",
                        // height: "144px",
                        cursor: "pointer",
                        width: { xs: "80px", sm: "144px" },
                        height: { xs: "80px", sm: "144px" },
                      }}
                    >
                      <img
                        style={{
                          // width: "140px",
                          // height: "140px",
                          width:"100%", 
                          borderRadius: "50%",
                        }}
                        typeof="file"
                        alt="profile"
                        src={
                          selectedAvatar?.value ||
                          userDetails?.secondaryAvatar ||
                          PlaceholderAvatar
                        }
                      />
                    </Box>

                    <Typography
                      sx={{ 
                        color: "rgba(255, 255, 255, 0.5)",
                        fontSize:"16px",
                        lineHeight:"24px",
                        letterSpacing:"-2%",
                        fontWeight:"500",
                        width:{ xs: "104px", sm: "auto", lg: "auto" },
                      }}
                      // variant="body1"
                    >
                      Choose Avatar
                    </Typography>
                  </Box>
                </Box>

                {/* -----Action buttons container----- */}
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "10px",
                    flexDirection: { xs: "column-reverse", sm: "row" },
                  }}
                >
                  <ButtonSecondary
                    onClick={() => {
                      props?.handleDialog();
                    }}
                    sx={{
                      height: "52px",
                      width: "100%",
                      padding: "15px 16px",
                      background: "transparent",
                      borderRadius: "14px",
                    }}
                    LabelStyle={{
                      fontSize: "18px",
                      fontWeight: "600",
                      lineHeight: "22px",
                    }}
                    label="Cancel"
                  />
                  <ButtonPrimary
                    isLoading={loading}
                    onClick={() => {
                      handleSaveAvatar();
                    }}
                    sx={{
                      height: "52px",
                      width: "100%",
                      padding: "15px 16px",
                      borderRadius: "14px",
                      border: "1px solid #526600",
                    }}
                    LabelStyle={{
                      fontSize: "18px",
                      fontWeight: "600",
                      lineHeight: "22px",
                    }}
                    label="Save"
                  />
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </>
    )
  );
};

export default ProfileDialog;
