import { CloseSharp } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { ImageContainer } from "../../shared/components/ImageContainer";
import { FigmaIcon } from "../../assets/icons/icons";
import { LockIconSvg } from "../../assets/icons/LockIconSvg";
import { AlertTriangleIconSvg } from "../../assets/icons/AlertTriangleIconSvg";
import { MessageQuestionIconSvg } from "../../assets/icons/MessageQuestionIconSvg";
import Chip from "../../shared/components/Chip";
import ComponentCard from "../home/components/ComponentCard";
import ReportDialog from "../projectComponent/ReportDialog";
import FeedbackDialog from "../projectComponent/FeedbackDialog";
import {
  getComponentSourceCodeByComponentId,
  getRelatedComponents,
} from "../../services/component";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { toastError, toastSuccess } from "../../utils/ToastMessage";
import { useGlobalContext } from "../../App";
import { getUserById } from "../../services/user";
import { CopyIconSvg } from "../../assets/icons/CopyIconSvg";
import { isBrowserSafari, monthStringFormatDate } from "../../utils/extensions";
import Seperator2 from "../../shared/components/Seperator2";
import { ButtonPrimary } from "../../shared/components/button/Buttons";
import DefaultLoading from "../../shared/Loading/DefaultLoading";
import PremiumIconSvg from "../../assets/icons/PremiumIcon/PremiumIconSvg";

type Prop = {
  // setComponentDetailsPopup: any;
  // componentDetailsPopup: boolean;
  component?: any;
  onClose?: () => void;
};
const ProjectComponentDetailScreenPopUp = (props: Prop) => {
  const navigate = useNavigate();
  const detailsRef = useRef<HTMLDivElement | null>(null);
  const handleClickOutsideRef = useRef<HTMLDivElement | null>(null);
  const [loading, setLoading] = useState(false);
  const [loadingComponent, setLoadingComponent] = useState(false);

  const { userDetails, setActiveLoginDialog } = useGlobalContext();
  const [showFeedbackDialog, setShowFeedbackDialog] = useState(false);
  const [showReportDialog, setShowReportDialog] = useState(false);
  const [components, setComponents] = useState([]);
  const [userSubscriptions, setUserSubscriptions] = useState([]);
  const [isSubscribed, setIsSubscribed] = useState(false);
  // let [component, setComponent] = useState<any>({});

  const [componentDetailsPopup, setComponentDetailsPopup] =
    useState<boolean>(false);
  const [selectedComponent, setSelectedComponent] = useState(props.component);
  const handleOpenPopup = (component: any) => {
    setSelectedComponent(component);
    setComponentDetailsPopup(true);

    // Update the URL without navigating
    const newUrl = `${component.blurbUrl}`;
    window.history.replaceState(null, "", newUrl);
  };

  const location = useLocation();
  const pathName = location.pathname;
  const urlArray = pathName.split("/");
  const blurbUrl = urlArray[2];

  const handleCloseReportDialog = () => {
    setShowReportDialog(false);
  };

  const handleCloseFeedbackDialog = () => {
    setShowFeedbackDialog(false);
  };

  function getRandomPageNumber() {
    return Math.floor(Math.random() * 50) + 1;
  }

  const pageNumber = getRandomPageNumber();
  const getComponents = async () => {
    setLoadingComponent(true);
    try {
      await getRelatedComponents(props?.component)
        .then((res: any) => {
          setComponents(res.paginatedComponents);
        })
        .catch((err: any) => {
          console.log("err", err);
        });
      setLoadingComponent(false);
    } catch (error) {
      console.log(error);
      setLoadingComponent(false);
    }
  };

  const getComponentByBlurb = async () => {
    // try {
    //   await getComponentByBlurbUrl(blurbUrl)
    //     .then((res: any) => {
    //       setComponent(res?.data);
    //     })
    //     .catch((err: any) => {
    //       console.log("err", err);
    //     });
    // } catch (error) {
    //   console.log(error);
    // }
  };

  const handleCopyFigmaCode = async () => {
    setLoading(true);
    if (!Cookies.get("jstoken")) {
      setActiveLoginDialog(true);
      setLoading(false);
      return;
    }
    if (isSubscribed || props?.component?.license === "FREE") {
      try {
        const componentSourceCode: any =
          await getComponentSourceCodeByComponentId(props?.component?.id);
        const htmlContent = componentSourceCode?.data?.figmaCode;
        // Create a Blob with the HTML content and specify the MIME type as 'text/html'
        const blob = new Blob([htmlContent], { type: "text/html" });
        const clipboardItem = new ClipboardItem({ "text/html": blob });
        // Copy the Blob to the clipboard

        if (isBrowserSafari()) {
          setTimeout(() => {
            navigator.clipboard
              .write([clipboardItem])
              .then(() => {
                toastSuccess("Successfull", "Component is copied");
              })
              .catch((err) => {
                toastError(
                  "Unable to copy component",
                  "We suggest using Google Chrome for the best experience."
                );
              });
          }, 0);
        } else {
          navigator.clipboard
            .write([clipboardItem])
            .then(() => {
              toastSuccess("Successfull", "Component is copied");
            })
            .catch((err) => {
              toastError(
                "Unable to copy component",
                "Verify whether the browser is preventing the copy to clipboard feature."
              );
            });
        }
      } catch (error) {
        console.error("Copy failed:", error);
        toastError("Failed", "Something went wrong.");
      }
    } else {
      navigate("/pricing");
      toastError("Please Subscribe to copy the Component.");
    }
    setLoading(false);
  };

  useEffect(() => {
    getComponents();
    getComponentByBlurb();
  }, [location]);

  const getUserSubscriptionsDetails = async (id: string) => {
    const response = await getUserById(id);
    const subscriptions = response?.data?.subscriptions;

    if (subscriptions?.length > 0) {
      setUserSubscriptions(subscriptions);
      for (let subscription of subscriptions) {
        if (subscription.status === "ACTIVE") {
          setIsSubscribed(true);
        }
      }
    }
  };

  useEffect(() => {
    if (userDetails) {
      getUserSubscriptionsDetails(userDetails._id);
    }
  }, [userDetails]);

  const handleClosePopup = () => {
    if (props.onClose) {
      props.onClose();
    }
  };
  // Function to handle clicks outside the popup
  const handleClickOutside = (event: MouseEvent) => {
    if (
      handleClickOutsideRef.current &&
      !handleClickOutsideRef.current.contains(event.target as Node)
    ) {
      handleClosePopup();
    }
  };

  useEffect(() => {
    // Add event listener for clicks
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (detailsRef.current) {
      detailsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [selectedComponent]);
  return (
    <>
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          bgcolor: "rgba(0, 0, 0, 0.60)",
          zIndex: 3000,
        }}
      >
        <Box
          sx={{
            width: "100vw",
            height: "100vh",
            bgcolor: "rgba(0, 0, 0, 0.60)",
            filter: "blur(62px)",
          }}
        />
      </Box>

      <Box
        sx={{
          position: "fixed",
          top: "0",
          left: "0",
          zIndex: 4000,
          width: "100vw",
          height: "100%",
          overflowY: "scroll",
          paddingBottom: { xs: "50px", sm: "80px", lg: "80px" },
          margin: "auto",
          scrollbarWidth: "none",
        }}
      >
        <Box ref={detailsRef} sx={{ width: "100%", mb: "80px" }}></Box>
        <Box
          ref={handleClickOutsideRef}
          sx={{ margin: "auto", width: "fit-content" }}
        >
          <Box
            sx={{
              maxWidth: "1245px",
              width: { xs: "95vw", sm: "80vw", lg: "80vw" },
              padding: { xs: "24px", sm: "40px", lg: "80px" },
              maxHeight: "auto",
              height: "auto",
              bgcolor: "#0C0C0C",
              borderRadius: { xs: "20px", sm: "40px", lg: "40px" },
              border: "1px solid #3D3D3D",
              cursor: "default",
              position: "relative",
              overflow: "hidden",
              scrollbarWidth: "none",
              margin: "auto",
            }}
          >
            {/*---------Component detail popup-------- */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
                justifyContent: "center",
              }}
            >
              {/* cancel icon  */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "absolute",
                  right: { xs: "14px", sm: "40px", md: "40px" },
                  top: { xs: "14px", sm: "40px", md: "40px" },
                  width: { xs: "25px", sm: "44px", md: "44px" },
                  height: { xs: "25px", sm: "44px", md: "44px" },
                  border: "1px solid #3D3D3D",
                  borderRadius: "70px",
                  padding: { xs: "15px", md: "10px" },
                }}
              >
                <CloseSharp
                  onClick={(e) => {
                    e.stopPropagation();
                    // props.setComponentDetailsPopup(!props.componentDetailsPopup);
                    handleClosePopup();
                  }}
                  sx={{
                    cursor: "pointer",
                    color: "white",
                  }}
                />
              </Box>

              {/* Details about component  */}
              <Box
                sx={{
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  {showReportDialog && (
                    <ReportDialog
                      activeDialog={showReportDialog}
                      handleDialog={handleCloseReportDialog}
                      data={selectedComponent}
                    />
                  )}

                  {showFeedbackDialog && (
                    <FeedbackDialog
                      activeDialog={showFeedbackDialog}
                      handleDialog={handleCloseFeedbackDialog}
                      data={selectedComponent}
                    />
                  )}

                  {/* Main Section */}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "40px",
                    }}
                  >
                    {/* ------Content detail section------ */}
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "32px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "12px",
                        }}
                      >
                        <Typography
                          sx={{ color: "white", fontWeight: "600" }}
                          variant="h4"
                        >
                          {/* {selectedComponent?.title} */}
                          <Box
                            sx={{
                              display: "flex",
                              gap: "8px",
                              alignItems: "center",
                            }}
                          >
                            {selectedComponent?.title}
                            {selectedComponent?.license === "PREMIUM" ? (
                              <PremiumIconSvg />
                            ) : (
                              ""
                            )}
                          </Box>
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            color: "rgba(255, 255, 255, 0.5)",
                            fontWeight: "400",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {selectedComponent?.blurb}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          position: "relative",
                          // flexDirection: { xs: "column", md: "row" },
                          flexDirection: "column",
                          width: "100%",
                          gap: "20px",
                          "@media (min-width: 1120px)": {
                            flexDirection: "row",
                          },
                          "@media (max-width: 1119px)": {
                            gap: "16px",
                          },
                        }}
                      >
                        {/* ----Preview Image---- */}
                        <Box
                          sx={{
                            flex: 1,
                            borderRadius: "14px",
                            // bgcolor: "#1B1B1B",
                            position: "relative",
                            width: "100%",
                            maxWidth: "100%",
                            overflow: "hidden",
                            "&::before": {
                              content: '""',
                              display: "block",
                              paddingTop: "75%",
                            },
                            // border:"1px solid red"
                          }}
                        >
                          <Box
                            sx={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              flex: 1,
                              width: "100%",
                              height: "100%",
                              borderRadius: "14px",
                            }}
                          >
                            <Box>
                              <img
                                src={
                                  selectedComponent?.documents
                                    ? selectedComponent?.documents[0]?.url
                                    : ""
                                }
                                alt="Preview"
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "contain",
                                  borderRadius: "14px",
                                }}
                              />
                            </Box>
                          </Box>
                        </Box>

                        {/* -----Details----- */}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "16px",
                            maxWidth: "338px",
                            width: "100%",
                            justifyContent: "flex-start",
                            "@media (max-width: 1119px)": {
                              maxWidth: "100%",
                              // mt:"-50px"
                            },
                            // "@media screen and (min-width:800px) and (max-width: 960px)": {
                            //   mt:"-42px"
                            // },
                            // "@media screen and (min-width:730px) and (max-width: 799px)": {
                            //   mt:"-42px"
                            // },
                            // "@media screen and (min-width:501px) and (max-width: 729px)": {
                            //   mt:"-32px"
                            // },
                            // "@media screen and (min-width:401px) and (max-width: 500px)": {
                            //   mt:"-22px"
                            // },
                            // "@media screen and (min-width:300px) and (max-width: 400px)": {
                            //   mt:"-20px"
                            // },
                            // border:"1px solid red"
                          }}
                        >
                          {/* ---Figma--- */}
                          <Box
                            onClick={(e: any) => {
                              e.preventDefault();
                              handleCopyFigmaCode();
                            }}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              bgcolor: "#1B1B1B",
                              boxShadow: "inset 0px 0px 0px 1px #3D3D3D",
                              borderRadius: "14px",
                              justifyContent: "space-between",
                              padding: "15px",
                              cursor: "pointer",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "12px",
                              }}
                            >
                              <ImageContainer
                                style={{ width: "24px", height: "24px" }}
                                title="figma"
                              >
                                {FigmaIcon}
                              </ImageContainer>
                              <Typography
                                variant="body1"
                                sx={{
                                  color: "rgba(255, 255, 255, 0.5)",
                                  fontWeight: "400",
                                }}
                              >
                                Figma
                              </Typography>
                            </Box>

                            {loading ? (
                              <Box
                                sx={{
                                  width: "26px",
                                  height: "26px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <DefaultLoading />
                              </Box>
                            ) : (Cookies.get("jstoken") && isSubscribed) ||
                              selectedComponent?.license === "FREE" ? (
                              <CopyIconSvg color="white" />
                            ) : (
                              <Box
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                }}
                              >
                                <LockIconSvg color="#CCFF00" />
                              </Box>
                            )}
                          </Box>

                          {/* ---Overview--- */}
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                              gap: "12px",
                              bgcolor: "#1B1B1B",
                              boxShadow: "inset 0px 0px 0px 1px #3D3D3D",
                              borderRadius: "14px",
                              justifyContent: "space-between",
                              padding: "24px",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "white",
                                fontWeight: "600",
                              }}
                              variant="h4"
                            >
                              Overview
                            </Typography>
                            <Typography
                              sx={{
                                color: "rgba(255, 255, 255, 0.5)",
                                fontWeight: "400",
                                lineHeight: "24px",
                              }}
                              variant="body1"
                            >
                              {selectedComponent?.description}
                            </Typography>
                          </Box>

                          {/* ------Product Description------ */}
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                              gap: "12px",
                              bgcolor: "#1B1B1B",
                              boxShadow: "inset 0px 0px 0px 1px #3D3D3D",
                              borderRadius: "14px",
                              justifyContent: "space-between",
                              padding: "24px",
                              width: "100%",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "white",
                                fontWeight: "600",
                              }}
                              variant="h4"
                            >
                              Product Description
                            </Typography>

                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "12px",
                                width: "100%",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  width: "100%",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "rgba(255, 255, 255, 0.5)",
                                    fontWeight: "400",
                                  }}
                                  variant="body1"
                                >
                                  Category
                                </Typography>

                                <Typography
                                  sx={{
                                    color: "white",
                                    fontWeight: "400",
                                  }}
                                  variant="body1"
                                >
                                  Content
                                </Typography>
                              </Box>

                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  width: "100%",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "rgba(255, 255, 255, 0.5)",
                                    fontWeight: "400",
                                  }}
                                  variant="body1"
                                >
                                  Release
                                </Typography>

                                <Typography
                                  sx={{
                                    color: "white",
                                    fontWeight: "400",
                                  }}
                                  variant="body1"
                                >
                                  {selectedComponent?.createdAt
                                    ? monthStringFormatDate(
                                        selectedComponent?.createdAt
                                      )
                                    : "-"}
                                </Typography>
                              </Box>

                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  width: "100%",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "rgba(255, 255, 255, 0.5)",
                                    fontWeight: "400",
                                  }}
                                  variant="body1"
                                >
                                  Author
                                </Typography>

                                <Typography
                                  sx={{
                                    color: "white",
                                    fontWeight: "400",
                                  }}
                                  variant="body1"
                                >
                                  UI Wiki
                                </Typography>
                              </Box>
                            </Box>
                          </Box>

                          {/* ---- Give Feedback----         */}
                          <Box
                            onClick={() => {
                              if (Cookies.get("jstoken")) {
                                setShowFeedbackDialog(true);
                              } else {
                                navigate("/login");
                              }
                            }}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              bgcolor: "#1B1B1B",
                              boxShadow: "inset 0px 0px 0px 1px #3D3D3D",
                              borderRadius: "14px",
                              justifyContent: "flex-start",
                              padding: "15px",
                              gap: "12px",
                              cursor: "pointer",
                            }}
                          >
                            <MessageQuestionIconSvg />
                            <Typography
                              variant="subtitle1"
                              sx={{ color: "white", fontWeight: "600" }}
                            >
                              Give Feedback
                            </Typography>
                          </Box>

                          {/* -----Repoart a problem-----   */}
                          <Box
                            onClick={() => {
                              if (Cookies.get("jstoken") && userDetails?.id) {
                                setShowReportDialog(true);
                              } else {
                                navigate("/login");
                              }
                            }}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              bgcolor: "#1B1B1B",
                              boxShadow: "inset 0px 0px 0px 1px #3D3D3D",
                              borderRadius: "14px",
                              justifyContent: "flex-start",
                              padding: "15px",
                              gap: "12px",
                              cursor: "pointer",
                            }}
                          >
                            <AlertTriangleIconSvg />
                            <Typography
                              variant="subtitle1"
                              sx={{ color: "white", fontWeight: "600" }}
                            >
                              Reports a problem
                            </Typography>
                          </Box>
                        </Box>
                      </Box>

                      {/* ------Tags------ */}
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "12px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontWeight: "600",
                            lineHeight: "24px",
                            color: "#FFFFFF",
                          }}
                        >
                          Component Tags
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            width: "100%",
                            flexWrap: "wrap",
                            gap: "8px",
                          }}
                        >
                          {selectedComponent?.tags?.map(
                            (tag: string, index: number) => {
                              return <Chip index={index} title={tag} />;
                            }
                          )}
                        </Box>
                      </Box>
                    </Box>

                    {/* --------Seperator -------- */}
                    <Box>
                      <Seperator2 />
                    </Box>

                    {/* --------Related Cards Section-------- */}
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "40px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          sx={{ fontWeight: "600" }}
                          color="white"
                          variant="h3"
                        >
                          Related Components
                        </Typography>
                      </Box>
                      {!loadingComponent ? (
                        <Box
                          sx={{
                            display: "grid",
                            flexWrap: "wrap",
                            gridTemplateColumns: {
                              xs: "repeat(1,1fr)",
                              sm: "repeat(2,1fr)",
                              lg: "repeat(3,1fr)",
                            },
                            gap: "16px",
                          }}
                        >
                          {components.slice(0, 6)?.map((data, index) => (
                            <Box key={index}>
                              <ComponentCard
                                data={data}
                                userSubscriptions={userSubscriptions}
                                isSubscribed={isSubscribed}
                                onEyeIconClick={() => handleOpenPopup(data)}
                              />
                            </Box>
                          ))}
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            sx={{
                              width: "42px",
                              height: "42px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <DefaultLoading />
                          </Box>
                        </Box>
                      )}
                    </Box>

                    {/* -----View More Button----  */}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <ButtonPrimary
                        onClick={() => {
                          navigate("/search");
                          if (props.onClose) {
                            props.onClose();
                          }
                        }}
                        sx={{ width: "150px", height: "54px" }}
                        label="View More"
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ProjectComponentDetailScreenPopUp;
