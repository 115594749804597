import React, { useEffect, useRef, useState } from "react";
import { Box, Typography } from "@mui/material";
import { CloseSharp } from "@mui/icons-material";
import {
  ButtonPrimary,
  ButtonSecondary,
} from "../../../../shared/components/button/Buttons";
import Avatar from "./components/Avatar";
import {
  UiWikiAvt1,
  UiWikiAvt10,
  UiWikiAvt11,
  UiWikiAvt12,
  UiWikiAvt13,
  UiWikiAvt14,
  UiWikiAvt15,
  UiWikiAvt16,
  UiWikiAvt17,
  UiWikiAvt18,
  UiWikiAvt19,
  UiWikiAvt2,
  UiWikiAvt20,
  UiWikiAvt21,
  UiWikiAvt22,
  UiWikiAvt23,
  UiWikiAvt24,
  UiWikiAvt25,
  UiWikiAvt26,
  UiWikiAvt27,
  UiWikiAvt28,
  UiWikiAvt29,
  UiWikiAvt3,
  UiWikiAvt30,
  UiWikiAvt31,
  UiWikiAvt32,
  UiWikiAvt33,
  UiWikiAvt34,
  UiWikiAvt4,
  UiWikiAvt5,
  UiWikiAvt6,
  UiWikiAvt7,
  UiWikiAvt8,
  UiWikiAvt9,
} from "../../../../assets/images/image";
import CrossIcon from "../../../../assets/icons/Cross Icon/CrossIcon";

type Props = {
  activeDialog: any;
  handleDialog?: any;
  activeId: any;
};

const AvatarDialog: React.FC<Props> = (props) => {
  const [selectedAvatar, setSeletectedAvatar] = useState("");
  const [isScrolledTop, setIsScrolledTop] = useState(false);
  const [isScrolledBottom, setIsScrolledBottom] = useState(true);
  const dialogRef = useRef<HTMLDivElement>(null);

  const handleScroll = (event: any) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;

    if (scrollTop > 0) {
      setIsScrolledTop(true);
    } else {
      setIsScrolledTop(false);
    }

    if (scrollTop + clientHeight >= scrollHeight - 15) {
      setIsScrolledBottom(false);
    } else {
      setIsScrolledBottom(true);
    }
  };

  const avatarsList = [
    { key: "UiWikiAvt1", value: UiWikiAvt1 },
    { key: "UiWikiAvt2", value: UiWikiAvt2 },
    { key: "UiWikiAvt3", value: UiWikiAvt3 },
    { key: "UiWikiAvt4", value: UiWikiAvt4 },
    { key: "UiWikiAvt5", value: UiWikiAvt5 },
    { key: "UiWikiAvt6", value: UiWikiAvt6 },
    { key: "UiWikiAvt7", value: UiWikiAvt7 },
    { key: "UiWikiAvt8", value: UiWikiAvt8 },
    { key: "UiWikiAvt9", value: UiWikiAvt9 },
    { key: "UiWikiAvt10", value: UiWikiAvt10 },
    { key: "UiWikiAvt11", value: UiWikiAvt11 },
    { key: "UiWikiAvt12", value: UiWikiAvt12 },
    { key: "UiWikiAvt13", value: UiWikiAvt13 },
    { key: "UiWikiAvt14", value: UiWikiAvt14 },
    { key: "UiWikiAvt15", value: UiWikiAvt15 },
    { key: "UiWikiAvt16", value: UiWikiAvt16 },
    { key: "UiWikiAvt17", value: UiWikiAvt17 },
    { key: "UiWikiAvt18", value: UiWikiAvt18 },
    { key: "UiWikiAvt19", value: UiWikiAvt19 },
    { key: "UiWikiAvt20", value: UiWikiAvt20 },
    { key: "UiWikiAvt21", value: UiWikiAvt21 },
    { key: "UiWikiAvt22", value: UiWikiAvt22 },
    { key: "UiWikiAvt23", value: UiWikiAvt23 },
    { key: "UiWikiAvt24", value: UiWikiAvt24 },
    { key: "UiWikiAvt25", value: UiWikiAvt25 },
    { key: "UiWikiAvt26", value: UiWikiAvt26 },
    { key: "UiWikiAvt27", value: UiWikiAvt27 },
    { key: "UiWikiAvt28", value: UiWikiAvt28 },
    { key: "UiWikiAvt29", value: UiWikiAvt29 },
    { key: "UiWikiAvt30", value: UiWikiAvt30 },
    { key: "UiWikiAvt31", value: UiWikiAvt31 },
    { key: "UiWikiAvt32", value: UiWikiAvt32 },
    { key: "UiWikiAvt33", value: UiWikiAvt33 },
    { key: "UiWikiAvt34", value: UiWikiAvt34 },
  ];

  const handleSelectAvatar = (data: any) => {
    setSeletectedAvatar(data);
  };

  const handleSaveAvatar = () => {
    props?.handleDialog(selectedAvatar);
  };

  useEffect(() => {}, [props?.activeDialog]);
  return (
    props?.activeDialog && (
      <>
        <Box
          ref={dialogRef}
          sx={{
            minWidth: { xs: "90vw", sm: "100%" },
            maxWidth: { xs: "20vw", sm: "956px" },
            width: "956px",
            // maxHeight: {xs:"442px",sm:"642px",lg:"700px"},
            // minHeight: "700px",
            maxHeight: {xs:"442px",sm:"543px",lg:"543px"},
            // minHeight: "543px",
            padding: { xs: "0px", sm: "24px" },
            height: "100%",
            bgcolor: "#1B1B1B",
            borderRadius: "24px",
            border: "1px solid #3D3D3D",
            cursor: "default",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "auto",
            gap: { xs: "24px", md: "32px" },
            "@media screen and (max-width: 1000px)": {
              maxWidth: "80% !important",
              width: "100%",
              // padding: "64px 52px 64px 52px",
              padding: "24px",
            },
          }}
        >
          <Box
            sx={{
              width: "100%",
              // border: "1px solid red",
              "@media screen and (min-width: 341px)and (max-width: 700px)": {
              // maxWidth: "90% !important",
              // width: "100%",
              // padding: "24px",
            },
            "@media screen and (max-width: 340px)": {
              // maxWidth: "75% !important",
              // width: "100%",
              // padding: "24px",
            },
          }}
          >
            <Typography
              // variant="h4"
              sx={{
                color: "#FFFFFF",
                fontSize: {xs:"18px",sm:"32px",lg:"32px"},
                fontWeight: "500",
                width: "100%",
                // maxWidth: "796px",
              }}
            >
              Select an avatar
            </Typography>
            <Box
              onClick={(e) => {
                e.stopPropagation();
                props?.handleDialog();
              }}
              sx={{
                position: "absolute",
                right: "20px",
                top: "20px",
                cursor: "pointer",
                color: "white",
              }}
            >
              <CrossIcon />
            </Box>
          </Box>

          {/* Gradient Overlay */}
          {isScrolledTop && (
            <Box
              sx={{
                position: "absolute ",
                // border: "1px solid red",
                width: "100%",
                top: { xs: "133px", sm: "133px", md: "130px", lg: "90px" },
                height: { xs: "40px", sm: "112px", md: "112px", lg: "112px" },
                background:
                  "linear-gradient(to bottom, #1B1B1B, rgba(255, 255, 255, 0))",
                pointerEvents: "none",
                zIndex: 10,
                // "@media screen and (min-width: 1410px)": {
                //   maxWidth: "90% !important",
                //   top: "82px",
                // },
                // "@media screen and (min-width: 1280px) and (max-width: 1409px)":
                //   {
                //     maxWidth: "90% !important",
                //     top: "83px",
                //   },
                "@media screen and (min-width: 1001px) and (max-width: 1279px)":
                  {
                    maxWidth: "90% !important",
                    top: "92px",
                  },
                "@media screen and (min-width: 960px) and (max-width: 1000px)":
                  {
                    maxWidth: "100% !important",
                    top: "92px",
                  },
                "@media screen and (min-width: 600px) and (max-width: 959px)": {
                  maxWidth: "100% !important",
                  top: "84px",
                },
                // "@media screen and (min-width: 600px) and (max-width: 600px)": {
                //   maxWidth: "90% !important",
                //   top: "101px",
                // },
                "@media screen and (min-width: 0px) and (max-width: 599px)": {
                  maxWidth: "100% !important",
                  top: "68px",
                },
              }}
            />
          )}

          {/* Avatars List*/}
          <Box
            onScroll={handleScroll}
            sx={{
              position: "relative",
              // border: "1px solid red",
              maxWidth: "740px",
              display: "flex",
              flexWrap: "wrap",
              gridAutoFlow: "row dense",
              // minWidth: "740px",
              width: "100%",
              overflowY: "scroll",
              scrollbarWidth: "none",
              // maxHeight: "504px",
              maxHeight: {xs:"150px",sm:"320px",lg:"504px"},
              // mb: {xs:"24px",sm:"0px",lg:"0px"},
              // mt: {xs:"24px",sm:"0px",lg:"0px"},
              paddingTop: "0px",
              // maxHeight: "340px",
              height: "100%",
              pt: "16px",
              // marginX: "84px",
              boxSizing: "border-box",
              gridAutoRows:
                "var(--breakpoint-grid_auto-rows, minmax(min-content,max-content) )",
              gridGap:
                "var(--breakpoint-grid_row-gap,40) var(--breakpoint-grid_column-gap,60)",
              gridTemplateColumns:
                "var(--breakpoint-grid_column-template, repeat(var(--breakpoint-grid_columns,1),minmax(0,1fr)) )",
              "--breakpoint-grid_row-gap": "37px",
              "--breakpoint-grid_column-gap": "60px",
              "@media screen and (min-width: 1880px)": {
                gridTemplateColumns: "repeat(4, 1fr) !important",
              },
              "@media screen and (min-width: 1700px) and (max-width: 1900px)": {
                gridTemplateColumns: "repeat(4, 1fr) !important",
              },
              "@media screen and (min-width: 1440px) and (max-width: 1700px)": {
                gridTemplateColumns: "repeat(4, 1fr) !important",
              },
              "@media screen and (min-width: 1000px) and (max-width: 1440px)": {
                gridTemplateColumns: "repeat(4, 1fr) !important",
              },
              "@media screen and (min-width: 700px) and (max-width: 1000px)": {
                display: "grid !important",
                maxWidth: "100%",
                minWidth: "100%",
                gridTemplateColumns: "repeat(3, 1fr) !important",
              },
              "@media screen and (max-width: 700px)": {
                display: "grid !important",
                maxWidth: "100%",
                minWidth: "100%",
                gridTemplateColumns: "repeat(2, 1fr) !important",
              },
              "@media screen and (max-width: 599px)": {
                gridTemplateColumns: " repeat(4, 1fr) !important",
                gap: "24px",
              },
              "@media screen and (max-width: 375px)": {
                gridTemplateColumns: " repeat(3, 1fr) !important",
              },
              "@media screen and (max-width: 332px)": {
                gridTemplateColumns: " repeat(2, 1fr) !important",
              },
            }}
          >
            {avatarsList?.map((avatar) => {
              return (
                <Avatar
                  selectedAvatar={selectedAvatar}
                  activeId={props?.activeId}
                  handleSelectAvatar={handleSelectAvatar}
                  data={avatar}
                />
              );
            })}
          </Box>

          {/* Gradient Overlay */}
          {isScrolledBottom && (
            <Box
              sx={{
                position: "absolute",
                // border: "1px solid red",
                width: "100%",
                bottom: { xs: "146px", sm: "146px", md: "146px", lg: "105px" },
                height: { xs: "40px", sm: "80px", md: "80px", lg: "80px" },
                background:
                  "linear-gradient(to top, #1B1B1B, rgba(255, 255, 255, 0))",
                pointerEvents: "none",
                zIndex: 10,
                // "@media screen and (min-width: 2100px) and (max-width: 2500px)":
                //   {
                //     maxWidth: "100% !important",
                //     width: "100%",
                //     bottom: "107px",
                //   },
                // "@media screen and (min-width: 1675px) and (max-width: 1800px)":
                //   {
                //     maxWidth: "100% !important",
                //     width: "100%",
                //     bottom: "107px",
                //   },
                "@media screen and (min-width: 1300px) and (max-width: 1595px)":
                  {
                    maxWidth: "100% !important",
                    width: "100%",
                    bottom: "106px",
                  },
                "@media screen and (min-width: 1241px) and (max-width: 1279px)":
                  {
                    // maxWidth: "100% !important",
                    // width: "100%",
                    bottom: "106px",
                  },
                "@media screen and (min-width: 1001px) and (max-width: 1240px)":
                  {
                    // maxWidth: "100% !important",
                    // width: "100%",
                    bottom: "105px",
                  },
                "@media screen and (min-width: 960px) and (max-width: 1000px)": {
                  maxWidth: "100% !important",
                  width: "100%",
                  bottom: "105px",
                },
                "@media screen and (min-width: 600px) and (max-width: 959px)": {
                  maxWidth: "100% !important",
                  width: "100%",
                  bottom:"98px",
                },
                // "@media screen and (min-width: 600px) and (max-width: 700px)": {
                //   maxWidth: "100% !important",
                //   width: "100%",
                //   bottom:"132px",
                // },
                "@media screen and (min-width: 0px) and (max-width: 599px)": {
                  maxWidth: "100% !important",
                  width: "100%",
                  bottom:"158px",
                },
              }}
            />
          )}
          {/* -----Action buttons container----- */}
          <Box
            sx={{
              width: {xs:"100%",sm:"412px",lg:"412px"},
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: {xs:"8px",sm:"10px",lg:"10px"},
              flexDirection: { xs: "column", sm: "row" },
            }}
          >
            <ButtonSecondary
              onClick={(e) => {
                e.stopPropagation();
                props?.handleDialog();
              }}
              sx={{
                height: "52px",
                width: "100%",
                padding: "15px 16px",
                background: "transparent",
                borderRadius: "14px",
              }}
              LabelStyle={{
                fontSize: "18px",
                fontWeight: "600",
                lineHeight: "22px",
              }}
              label="Cancel"
            />

            <ButtonPrimary
              onClick={() => {
                handleSaveAvatar();
              }}
              sx={{
                height: "52px",
                width: "100%",
                padding: "15px 16px",
                borderRadius: "14px",
                border: "1px solid #526600",
              }}
              LabelStyle={{
                fontSize: "18px",
                fontWeight: "600",
                lineHeight: "22px",
              }}
              label="Save"
            />
          </Box>
        </Box>
      </>
    )
  );
};

export default AvatarDialog;
