import {
  Box,
  CircularProgress,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { ImageContainer } from "../../shared/components/ImageContainer";
import { ArrowRightIcon } from "../../assets/icons/ArrowRightIcon";
import NewsletterSection from "../../main/NewsletterSection";
import { FigmaIcon } from "../../assets/icons/icons";
import { LockIconSvg } from "../../assets/icons/LockIconSvg";
import { AlertTriangleIconSvg } from "../../assets/icons/AlertTriangleIconSvg";
import { MessageQuestionIconSvg } from "../../assets/icons/MessageQuestionIconSvg";
import Chip from "../../shared/components/Chip";
import ComponentCard from "../home/components/ComponentCard";
import ReportDialog from "./ReportDialog";
import FeedbackDialog from "./FeedbackDialog";
import {
  getAllComponents,
  getComponentByBlurbUrl,
  getComponentSourceCodeByComponentId,
} from "../../services/component";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { toastError, toastSuccess } from "../../utils/ToastMessage";
import { RotateElement } from "../../shared/components/Rotate";
import { useGlobalContext } from "../../App";
import { getUserById } from "../../services/user";
import { CopyIconSvg } from "../../assets/icons/CopyIconSvg";
import { isBrowserSafari, monthStringFormatDate } from "../../utils/extensions";
import { ButtonPrimary } from "../../shared/components/button/Buttons";
import { Breakpoint } from "../../assets/images/image";
import ProjectComponentDetailScreenPopUp from "./ProjectComponentDetailScreenPopUp";
import DefaultLoading from "../../shared/Loading/DefaultLoading";
import PremiumIconSvg from "../../assets/icons/PremiumIcon/PremiumIconSvg";
import Seperator2 from "../../shared/components/Seperator2";
import { useTheme } from "@mui/material/styles";
// import Seperator2 from "../../shared/components/Seperator2";

type Props = {};

const ProjectComponentDetailScreen = (props: Props) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const [loadingComponent, setLoadingComponent] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    userDetails,
    setActiveLoginDialog,
    activeLoginDialog,
    activeSignupDialog,
    activeResetDialog,
  } = useGlobalContext();
  const [showFeedbackDialog, setShowFeedbackDialog] = useState(false);
  const [showReportDialog, setShowReportDialog] = useState(false);
  const [components, setComponents] = useState([]);
  const [userSubscriptions, setUserSubscriptions] = useState([]);
  const [isSubscribed, setIsSubscribed] = useState(false);
  let [component, setComponent] = useState<any>({});

  const [originalUrl, setOriginalUrl] = useState(window.location.pathname);
  const [componentDetailsPopup, setComponentDetailsPopup] =
    useState<boolean>(false);
  const [selectedComponent, setSelectedComponent] = useState(null);
  const handleOpenPopup = (component: any) => {
    setSelectedComponent(component);
    setComponentDetailsPopup(true);

    // Update the URL without navigating
    const newUrl = `${component.blurbUrl}`;
    window.history.replaceState(null, "", newUrl);
    // setOriginalUrl(window.location.pathname);
  };

  const location = useLocation();
  const pathName = location.pathname; // Retrieve pathName from location
  const urlArray = pathName.split("/");
  const blurbUrl = urlArray[2];

  const carouselRef: any = useRef(null);

  const scrollLeft = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({ left: -300, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({ left: 300, behavior: "smooth" });
    }
  };

  const handleCloseReportDialog = () => {
    setShowReportDialog(false);
  };

  const handleCloseFeedbackDialog = () => {
    setShowFeedbackDialog(false);
  };
  function getRandomPageNumber() {
    return Math.floor(Math.random() * 50) + 1;
  }

  const pageNumber = getRandomPageNumber();
  const getComponents = async () => {
    setLoadingComponent(true);
    try {
      await getAllComponents({
        page: pageNumber,
        pageSize: 20,
      })
        .then((res: any) => {
          setComponents(res?.paginatedComponents);
        })
        .catch((err: any) => {
          console.log("err", err);
        });
      setLoadingComponent(false);
    } catch (error) {
      console.log(error);
      setLoadingComponent(false);
    }
  };

  const getComponentByBlurb = async () => {
    try {
      await getComponentByBlurbUrl(blurbUrl)
        .then((res: any) => {
          setComponent(res?.data);
        })
        .catch((err: any) => {
          console.log("err", err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleCopyFigmaCode = async () => {
    setLoading(true);
    if (!Cookies.get("jstoken")) {
      setActiveLoginDialog(true);
      setLoading(false);
      return;
    }
    if (isSubscribed || component?.license === "FREE") {
      try {
        const componentSourceCode: any =
          await getComponentSourceCodeByComponentId(component?.id);
        const htmlContent = componentSourceCode?.data?.figmaCode;
        // Create a Blob with the HTML content and specify the MIME type as 'text/html'
        const blob = new Blob([htmlContent], { type: "text/html" });
        const clipboardItem = new ClipboardItem({ "text/html": blob });
        // Copy the Blob to the clipboard

        if (isBrowserSafari()) {
          setTimeout(() => {
            navigator.clipboard
              .write([clipboardItem])
              .then(() => {
                toastSuccess("Successfull", "Component is copied");
              })
              .catch((err) => {
                toastError(
                  "Unable to copy component",
                  "We suggest using Google Chrome for the best experience."
                );
              });
          }, 0);
        } else {
          navigator.clipboard
            .write([clipboardItem])
            .then(() => {
              toastSuccess("Successfull", "Component is copied");
            })
            .catch((err) => {
              toastError(
                "Unable to copy component",
                "Verify whether the browser is preventing the copy to clipboard feature."
              );
            });
        }
      } catch (error) {
        console.error("Copy failed:", error);
        toastError("Failed", "Something went wrong.");
      }
    } else {
      navigate("/pricing");
      toastError("Please Subscribe to copy the Component.");
    }
    setLoading(false);
  };

  useEffect(() => {
    getComponents();
    getComponentByBlurb();
  }, [location]);

  const getUserSubscriptionsDetails = async (id: string) => {
    const response = await getUserById(id);
    const subscriptions = response?.data?.subscriptions;

    if (subscriptions?.length > 0) {
      setUserSubscriptions(subscriptions);
      for (let subscription of subscriptions) {
        if (subscription.status === "ACTIVE") {
          setIsSubscribed(true);
        }
      }
    }
  };

  useEffect(() => {
    if (userDetails) {
      getUserSubscriptionsDetails(userDetails._id);
    }
  }, [userDetails]);

  //Stop scrolling when popup is open
  useEffect(() => {
    if (
      componentDetailsPopup ||
      activeLoginDialog ||
      activeSignupDialog ||
      activeResetDialog ||
      showFeedbackDialog ||
      showReportDialog
    ) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [
    componentDetailsPopup,
    activeLoginDialog,
    activeSignupDialog,
    activeResetDialog,
    showFeedbackDialog,
    showReportDialog,
  ]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        padding: {
          xs: "0px 0px 0px 0px",
          sm: "0px 60px 0px 60px",
          md: "0px 80px 0px 80px",
          lg: "0px 170px 0px 170px",
        },
      }}
    >
      {showReportDialog && (
        <ReportDialog
          activeDialog={showReportDialog}
          handleDialog={handleCloseReportDialog}
          data={component}
        />
      )}

      {showFeedbackDialog && (
        <FeedbackDialog
          activeDialog={showFeedbackDialog}
          handleDialog={handleCloseFeedbackDialog}
          data={component}
        />
      )}

      {/* componentDetailsPopup when user click any perticular component  */}
      {componentDetailsPopup && (
        <ProjectComponentDetailScreenPopUp
          component={selectedComponent}
          onClose={() => {
            window.history.replaceState(null, "", originalUrl);
            setComponentDetailsPopup(false);
          }}
        />
      )}

      {/* Main Section */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: { xs: "100%", sm: "100%", lg: "100%" },
          padding: {
            xs: "24px 24px 40px 24px",
            sm: "40px 24px 40px 24px",
            lg: "40px 0px 80px 0px",
          },
        }}
      >
        {/* ------Content detail section------ */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: { xs: "24px", sm: "32px" },
          }}
        >
          {!isXs && (
            <Box sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
              <Typography
                sx={{ color: "white", fontWeight: "600" }}
                variant="h4"
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "8px",
                    alignItems: "center",
                  }}
                >
                  {component?.title}
                  {component?.license === "PREMIUM" ? <PremiumIconSvg /> : ""}
                </Box>
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  color: "rgba(255, 255, 255, 0.5)",
                  fontWeight: "400",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  fontSize: { xs: "16px" },
                }}
              >
                {component?.blurb}
              </Typography>
            </Box>
          )}

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: "24px",
              // height: "100%",
              "@media (min-width: 1070px)": {
                flexDirection: "row",
              },
              // "@media (max-width: 1069px)": {
              //   gap: "24px",
              // },
            }}
          >
            {/* ----Preview Image---- */}
            <Box
              sx={{
                flex: 1,
                borderRadius: "14px",
                // bgcolor: "#1B1B1B",
                position: "relative",
                maxWidth: "100%",
                width: "100%",
                overflow: "hidden",
                "&::before": {
                  content: '""',
                  display: "block",
                  paddingTop: "75%",
                },
                // border:"1px solid red"
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  flex: 1,
                  width: "100%",
                  height: "100%",
                  borderRadius: "14px",
                }}
              >
                <Box>
                  <img
                    src={
                      component?.documents ? component?.documents[0]?.url : ""
                    }
                    alt="Preview"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                      borderRadius: "14px",
                    }}
                  />
                </Box>
              </Box>
            </Box>

            {/* -----Details----- */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: { xs: "24px", sm: "20px" },
                maxWidth: { xs: "100%", sm: "338px" },
                width: "100%",
                justifyContent: "flex-start",
                "@media (max-width: 1069px)": {
                  maxWidth: "100%",
                },
                // "@media (max-width: 1068px)": {
                //   mt: "-30px",
                // },
                // "@media screen and (min-width: 731px) and (max-width: 900px)": {
                //   mt: "-22px",
                // },
                // "@media screen and (min-width: 450px) and (max-width: 730px)": {
                //   mt: "-10px",
                // },
                // "@media screen and (min-width: 300px) and (max-width: 449px)": {
                //   mt: "-3px",
                // },
                // "@media screen and (min-width: 0px) and (max-width: 300px)": {
                //   mt: "6px",
                // },
                // border:"1px solid red"
              }}
            >
              {isXs && (
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "12px" }}
                >
                  <Typography
                    sx={{ color: "white", fontWeight: "600" }}
                    variant="h4"
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: "8px",
                        alignItems: "center",
                      }}
                    >
                      {component?.title}
                      {component?.license === "PREMIUM" ? (
                        <PremiumIconSvg />
                      ) : (
                        ""
                      )}
                    </Box>
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "rgba(255, 255, 255, 0.5)",
                      fontWeight: "400",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      fontSize: { xs: "16px" },
                    }}
                  >
                    {component?.blurb}
                  </Typography>
                </Box>
              )}

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: { xs: "16px", sm: "20px" },
                  maxWidth: { xs: "100%", sm: "338px" },
                  width: "100%",
                  justifyContent: "flex-start",
                  "@media (max-width: 1069px)": {
                    maxWidth: "100%",
                  },
                }}
              >
                {/* ---Figma--- */}
                <Box
                  onClick={(e: any) => {
                    e.preventDefault();
                    handleCopyFigmaCode();
                  }}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    bgcolor: "#1B1B1B",
                    boxShadow: "inset 0px 0px 0px 1px #3D3D3D",
                    borderRadius: "14px",
                    justifyContent: "space-between",
                    padding: "15px",
                    cursor: "pointer",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "12px",
                    }}
                  >
                    <ImageContainer
                      style={{ width: "24px", height: "24px" }}
                      title="figma"
                    >
                      {FigmaIcon}
                    </ImageContainer>
                    <Typography
                      variant="body1"
                      sx={{
                        color: "rgba(255, 255, 255, 0.5)",
                        fontWeight: "400",
                      }}
                    >
                      Figma
                    </Typography>
                  </Box>

                  {loading ? (
                    <Box
                      sx={{
                        width: "26px",
                        height: "26px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <DefaultLoading />
                    </Box>
                  ) : (Cookies.get("jstoken") && isSubscribed) ||
                    component?.license === "FREE" ? (
                    <CopyIconSvg color="white" />
                  ) : (
                    <Box
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      <LockIconSvg color="#CCFF00" />
                    </Box>
                  )}
                </Box>

                {/* ---Overview--- */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "15px",
                    bgcolor: "#1B1B1B",
                    boxShadow: "inset 0px 0px 0px 1px #3D3D3D",
                    borderRadius: "14px",
                    justifyContent: "space-between",
                    padding: "24px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "white",
                      fontWeight: "600",
                    }}
                    variant="h4"
                  >
                    Overview
                  </Typography>
                  <Typography
                    sx={{
                      color: "rgba(255, 255, 255, 0.5)",
                      fontWeight: "400",
                      lineHeight: "24px",
                    }}
                    variant="body1"
                  >
                    {component?.description}
                  </Typography>
                </Box>

                {/* ------Product Description------ */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "20px",
                    bgcolor: "#1B1B1B",
                    boxShadow: "inset 0px 0px 0px 1px #3D3D3D",
                    borderRadius: "14px",
                    justifyContent: "space-between",
                    padding: "24px",
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      color: "white",
                      fontWeight: "600",
                    }}
                    variant="h4"
                  >
                    Product Description
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "12px",
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "rgba(255, 255, 255, 0.5)",
                          fontWeight: "400",
                        }}
                        variant="body1"
                      >
                        Category
                      </Typography>

                      <Typography
                        sx={{
                          color: "white",
                          fontWeight: "400",
                        }}
                        variant="body1"
                      >
                        Content
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "rgba(255, 255, 255, 0.5)",
                          fontWeight: "400",
                        }}
                        variant="body1"
                      >
                        Release
                      </Typography>

                      <Typography
                        sx={{
                          color: "white",
                          fontWeight: "400",
                        }}
                        variant="body1"
                      >
                        {component?.createdAt
                          ? monthStringFormatDate(component?.createdAt)
                          : "-"}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "rgba(255, 255, 255, 0.5)",
                          fontWeight: "400",
                        }}
                        variant="body1"
                      >
                        Author
                      </Typography>

                      <Typography
                        sx={{
                          color: "white",
                          fontWeight: "400",
                        }}
                        variant="body1"
                      >
                        UI Wiki
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                {/* ------Tags for mobile position according to figma mobile designs------ */}
                {isXs && (
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        flexWrap: "wrap",
                        gap: "8px",
                      }}
                    >
                      {component?.tags?.map((tag: string, index: number) => {
                        return <Chip index={index} title={tag} />;
                      })}
                    </Box>
                  </Box>
                )}

                <Box
                  onClick={() => {
                    if (Cookies.get("jstoken")) {
                      setShowFeedbackDialog(true);
                    } else {
                      navigate("/login");
                    }
                  }}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    bgcolor: "#1B1B1B",
                    boxShadow: "inset 0px 0px 0px 1px #3D3D3D",
                    borderRadius: "14px",
                    justifyContent: "flex-start",
                    padding: "15px",
                    gap: "12px",
                    cursor: "pointer",
                  }}
                >
                  <MessageQuestionIconSvg />
                  <Typography
                    variant="subtitle1"
                    sx={{ color: "white", fontWeight: "600" }}
                  >
                    Give Feedback
                  </Typography>
                </Box>

                <Box
                  onClick={() => {
                    if (Cookies.get("jstoken") && userDetails?.id) {
                      setShowReportDialog(true);
                    } else {
                      navigate("/login");
                    }
                  }}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    bgcolor: "#1B1B1B",
                    boxShadow: "inset 0px 0px 0px 1px #3D3D3D",
                    borderRadius: "14px",
                    justifyContent: "flex-start",
                    padding: "15px",
                    gap: "12px",
                    cursor: "pointer",
                  }}
                >
                  <AlertTriangleIconSvg />
                  <Typography
                    variant="subtitle1"
                    sx={{ color: "white", fontWeight: "600" }}
                  >
                    Reports a problem
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          {/* ------Tags for Tablets and desktop View position------ */}
          {!isXs && (
            <Box>
              <Typography
                sx={{
                  color: "#FFFFFF",
                  fontWeight: "600",
                  marginBottom: "12px",
                  fontSize: "16px",
                }}
                variant="body1"
              >
                Component Tags
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  flexWrap: "wrap",
                  gap: "8px",
                }}
              >
                {component?.tags?.map((tag: string, index: number) => {
                  return <Chip index={index} title={tag} />;
                })}
              </Box>
            </Box>
          )}

          {/* --------Seperator -------- */}
          <Box>
            <Seperator2 />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              paddingTop: { xs: "0px", md: "7px" },
              // paddingY: { xs: "48px", lg: "80px" },
              alignItems: "center",
              flex: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                flex: 1,
                width: "100%",
                gap: "40px",
              }}
            >
              <Typography
                variant="h3"
                color={"white"}
                sx={{ fontSize: "24px" }}
              >
                Related Components
              </Typography>
              {loadingComponent ? (
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: "42px",
                      height: "42px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <DefaultLoading />
                  </Box>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "grid",
                    width: "100%",
                    boxSizing: "border-box",
                    gridGap: "16px",
                    gridTemplateColumns: {
                      xs: "repeat(auto-fit, minmax(270px, 1fr))",
                      md: "repeat(auto-fit, minmax(360px, 1fr))",
                    }, // Adjust card size and fit into available space
                    "@media screen and (max-width: 579px)": {
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      flexWrap: "wrap",
                    },
                  }}
                >
                  {
                    //only need 6 components
                    components?.slice(0, 6)?.map((data: any, index: number) => {
                      return (
                        <Box
                          key={index}
                          sx={{
                            maxWidth: "100%",
                            minWidth: "100%",
                          }}
                        >
                          <ComponentCard
                            data={data}
                            isSubscribed={isSubscribed}
                            onEyeIconClick={() => handleOpenPopup(data)}
                          />
                        </Box>
                      );
                    })
                  }
                </Box>
              )}
            </Box>
            <ButtonPrimary
              onClick={() => {
                navigate("/search");
              }}
              sx={{
                width: "150px",
                height: "54px",
                mt: { xs: "40px", sm: "48px", lg: "48px" },
              }}
              label="View More"
            />
          </Box>
        </Box>

        {/* Newsletter section whne screen is small mobile view according to figma  */}
        {isXs && (
          <Box sx={{ width: "100%" }}>
            <Box sx={{ width: "100%", paddingY: { xs: "40px" } }}>
              <Seperator2 />
            </Box>
            <NewsletterSection />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ProjectComponentDetailScreen;
